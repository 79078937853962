import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticateUser } from "./redux/actions/index";
import Auth from "./_services/auth";
import LoginPage from "./_components/login.component";
import SignInOutContainer from "./container/index";
import Cookies from "universal-cookie";
import { get, post } from "../src/api.js";
import { API_URL_V2 } from "../src/_config/api.config.js";
import toast from "react-hot-toast";



function Login() {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const isLoading = useSelector((state) => state.isLoading);

  const [loaded, setLoaded] = React.useState(false);

  const authenticateCallback = React.useCallback( async () =>  {
    const token = cookies.get("token", { doNotParse: true });
    let body = new FormData();
    if (typeof token !== "undefined") {
      console.log("token is:", token);
      
      body.append("token", token);
    }
    else {
      let fullUrl = window.location.href;
      let url = new URL(fullUrl);
      let botId = url.searchParams.get("botId");
      let userId = url.searchParams.get("userId");
      // let body = new FormData();
      body.append('botId', botId)
      body.append('userId', userId)
      const response =await post(`${API_URL_V2}/authenticate`, body, true);
      if (response.success) {
        const cookies = new Cookies(null, { path: "/" });
        cookies.set("token", response.token, {
          expires: new Date(Date.now() + 72 * 60 * 60 * 1000),
        });
        console.log("Token is:", response.token);
        // let body = new FormData();
        // body.append("token", response.token);
        // dispatch(authenticateUser(body));
        // toast.success("Đăng nhập thành công!");
      } else {
        // toast.error("Email hoặc mật khẩu không đúng!");
      }
    }

    dispatch(authenticateUser(body));
  }, [dispatch]);

  const loginSuccessCallback = React.useCallback(() => {
    let url = new URL(window.location.href);
    let page = url.searchParams.get("page");
    let id = url.searchParams.get("id");
    let data = url.searchParams.get("data");
    let redirectUrl  = '/';

    if(page !== null) {
        redirectUrl += page
    }
    if(id !== null) {
        redirectUrl += `/${id}`
    }
    if(data !== null) {
        data = JSON.parse(data);
        for (let i = 0; i < data.length; i++) {
            let obj = Object.keys(data[i])
            redirectUrl += '?'+obj[0]+"="+data[i][obj[0]]
        }
    }
    navigate(redirectUrl)
}, [navigate]);

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      if (!isAuthenticated) {
        authenticateCallback();
      }
    }
    if (isAuthenticated) {
      loginSuccessCallback();
      // navigate("/");
    }
  }, [authenticateCallback, isAuthenticated, navigate, loaded, setLoaded, loginSuccessCallback]);

  return (
    <>
      {
        <div
          style={{
            backgroundImage: 'url("/images/coca-cola-background.png")',
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100vw",
            height: "100vh",
            padding: "20px",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <LoginPage />
          </div>
        </div>
      }
    </>
  );
}

export default Login;
